import * as React from 'react'
import '../components/colors.module.css'
import * as typography from '../components/typography.module.css'
import Layout from '../components/layout'

const IndexPage = () => {
  return (
    <Layout pageTitle="Home page">
      <h3 className={typography.headerDisabled}>Introduktion</h3>
        <p className={typography.bodyLarge}>
          CS-01 är en svensktillverkad helautomatisk saturator framtagen för att vara världens mest portabla och lätthanterliga blandningsanläggning för saltlake. <br></br><br></br>Saturatorn har en tillverkningskapacitet på upp till 15 m3/h och producerar
          saltlösning med en saltinblandning på 20-25%.
        </p>
    </Layout>
  )
}

export default IndexPage